body {
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c2653;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #1c2653;
  margin-top: 0;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
p {
  font-size: 17px;
  line-height: 1.4;
  color: #999faf;
  margin-top: 0;
}

section {
  position: relative;
  width: 100%;
  overflow: hidden;
}
